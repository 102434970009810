import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import styles from "../../../assets/jss/material-kit-react/views/impressumPage.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from '@material-ui/core/withWidth';

import instagramImage from 'assets/img/instagram.png'

class ContactSection extends React.Component {

    render() {
        const {classes, ...rest} = this.props;
        const isSM = this.props.width == 'sm' || this.props.width == 'xs';

        return <div className={classes.container}>
                <GridContainer>
                    <GridItem xs={12} md={4}>
                        <h2 className={classNames(classes.title, isSM ? classes.center : null)}>Kontakt</h2>
                        <h4 className={classNames(classes.description, isSM ? classes.center : null)}>
                            Hubenstein 1<br/>84416Taufkirchen (Vils)<br/><br/>
                            Telefonnummer:<br/>08084 8710 oder 08084 7248<br/><br/>

                            <img src={instagramImage} className={classNames(classes.socialIcons)}/>
                            <a href="https://instagram.com/gasthaus_hubenstein"> follow us on instagram</a>
                        </h4>
                    </GridItem>
                    <GridItem xs={12} md={8}>
                        <iframe title="Google Maps iframe for location of restaurant"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2651.459607325621!2d12.179230515788436!3d48.35169147924121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479e1e43586e91db%3A0x9abc9c25770fe036!2sPeter%20H%C3%A4ring!5e0!3m2!1sen!2sde!4v1616237540223!5m2!1sen!2sde&amp;z=200"
                            width="100%" height="500px" style={{border:0, borderRadius: "12px"}} allowFullScreen=""
                            loading="lazy"></iframe>
                    </GridItem>
                </GridContainer>
            </div>;
    }


}
export default withStyles(styles)(withWidth()(ContactSection));

