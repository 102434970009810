import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Header from "./../../components/Header/Header.jsx";
import Footer from "./../../components/Footer/Footer.jsx";
import GridContainer from "./../../components/Grid/GridContainer.jsx";
import GridItem from "./../../components/Grid/GridItem.jsx";
import HeaderLinks from "./../../components/Header/HeaderLinks.jsx";
import Parallax from "./../../components/Parallax/Parallax.jsx";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';

import styles from "./../../assets/jss/material-kit-react/views/lieferservicePage.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from '@material-ui/core/withWidth';

import {graphql, StaticQuery} from 'gatsby';
import ContactSection from "../ContactPage/Sections/ContactSection";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import CookieSnackbar from "../Components/CookieSnackbar";

const pageQuery = graphql`
  {
    allGraphCmsLieferservice {
      nodes {
        header {
          localFile {
            localURL
          }
        }
        gallary {       
            image { 
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            col
            alt      
        }
        title
        text
      }
    }
  }
`;

class LieferservicePage extends React.Component {
    render() {
        const {classes, ...rest} = this.props;
        const props = this.props;
        const imageClasses = classNames(classes.imgWidth, classes.imgRaised, classes.imgRoundedCircle, classes.imgFluid,);
        const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
        return <StaticQuery query={pageQuery}>{({allGraphCmsLieferservice: { nodes }}) => {
            const{gallary, text, title, header} = nodes[0]; // select first, whatever this is!
            const columns = props.width == 'sm' || props.width == 'xs' || props.width == 'md' ? 1 : 2
            return <div>
                <Header
                    color="transparent"
                    brand="Gasthaus Häring"
                    brandLink="/"
                    rightLinks={<HeaderLinks/>}
                    fixed
                    changeColorOnScroll={{
                        height: 200,
                        color: "dark"
                    }}
                    {...rest}
                />
                <Parallax small filter image={header.localFile.localURL}/>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.section}>
                        <div className={classes.container}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={6}>
                                    <div className={classes.profile}>
                                        <div className={classes.name}>
                                            <h2 className={classes.title}>{title}</h2>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                            <div className={classes.description} style={{paddingBottom: "20px"}}>
                                <h4>{text}</h4></div>
                            <ContactSection/>
                            <GridList cellHeight={320} className={classes.galleryGridList} cols={columns}>
                                {gallary.map(o =>
                                    <GridListTile key={o.image.url} col={o.col}>
                                        <GatsbyImage alt={o.alt} image={getImage(o.image.localFile)}/>
                                    </GridListTile>
                                )}
                            </GridList>
                        </div>
                    </div>
                    <div style={{paddingBottom: "60px"}}/>
                </div>
                <Footer/>
                <CookieSnackbar/>
            </div>}}
        </StaticQuery>;
    }
}

export default withWidth()(withStyles(styles)(LieferservicePage));
